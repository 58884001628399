<template>
    <div class="page projectpage">
      <h2>Forest Game ( working title )</h2>
      <iframe width="560" height="315" :src="`https://www.youtube.com/embed/${projects[td(project)].video}`" :title="`${td(project)}`" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

      <p class="regulartext">The Forest Game is an atmospheric non-linear exploration puzzle game. You explore a strange forest full of mystery in search for long forgotten items. The items have odd and sometimes hidden functions, and experimentation can open up the forest in unexpected ways.</p>
      <p class="regulartext">The game's progression revolves around puzzles, riddles and problem solving. Puzzles vary in style and scope, from crossing gaps and getting up on ledges, to large meta-puzzles which you solve over time as you acquire items and knowledge.</p>
      <p class="regulartext">The games progression revolve around puzzles and problem solving. Puzzles vary in style and scope, from crossing gaps to large meta-puzzles which you solve over time as you acquire items and knowledge.</p>
      <p class="regulartext">Another core pillar of the game is the overall ambience and feel of the world, inspired by scandinavian artists, literature and folklore, using pixel art mixed with modern techniques.</p>
      <p class="regulartext">If it looks interesting don't hesitate to get in contact at <a href="#" class="construct-mail" data-n="info" data-d="noumenongames" data-t="com" @click="mailto($event.currentTarget)" />, playable prototype available on request.</p>

      <div class="container">
        <div class="item" :key="gif" v-for="gif in projects[td(project)].gifs" ><img :src="`/gifs/forest_game/${gif}`"></div>
      </div>
    </div>
  </template>
  
  <script>
  export default { 
      props : { project : String },
      methods: {
      mailto: function(x)
      {
        window.location.href = `mailto: ${x.dataset.n}@${x.dataset.d}.${x.dataset.t}`
      },
      td: function(project) {
//        console.debug(this.projects.forest_game.gifs);
        return project.replace('-','_');
      }
    },
    data() {
        return { projects : { forest_game : { video : 'YdY54zgAlZo', gifs : require.context('/public/gifs/forest_game', true, /^.*\.(gif|png)$/).keys().map( x => x ) } } };
      }
  } 
  </script>
  
  <style lang="scss" scoped>
    .construct-mail:after 
    {
      content: attr(data-n) "@" attr(data-d) "." attr(data-t);
    }
    .projectpage
    {
      p
      {
        text-align: justify;
        margin-top:10px;
      }
      iframe
      {
        margin: auto;
        display: block;
      }
      .container
      {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        .item
        {
            margin: 12px auto;
            img
            {
                margin: auto;
                width: 100%;
            }
        }
      }
      ul
      {
        padding: 0px;
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        align-items: center;
        justify-content: center;
        li
        {
          margin: 40px;
          list-style: none;
          a
          {
            img
            {
                margin: auto;
                width: 100%;
            }
          }
        }
      }
    }
  </style>
  